<template>
	<div class="main-content">
		<ktv-breadcrumb title="Detail Farmer" page="List" folder="Farmer" />
		<div>
			<ktv-back @click="backToList()" />
			<br><br />
			<b-card-group deck>
				<vue-html2pdf
					ref="html2Pdf"
					:show-layout="false"
					:float-layout="false"
					:enable-download="true"
					:preview-modal="false"
					:paginate-elements-by-height="1400"
					:filename="detailItems.PersonName"
					:pdf-quality="2"
					:manual-pagination="false"
					pdf-format="a4"
					pdf-orientation="portrait"
					pdf-content-width="130%"
				>
					<section slot="pdf-content">
						<!-- PDF Content Here -->
					</section>
				</vue-html2pdf>

				<b-card title="" header-tag="header" footer-tag="footer">
					<template #header>
						<h6 class="mb-0" style="color:white"><b>Farmer Data</b></h6>
					</template>
					<b-tabs active-nav-item-class="nav nav-tabs">
						<b-tab title="Farmer's Profile">
							<b-button block disabled variant="default" size="md" class="text-left" style="background:#747D86 !important; color:white">{{ $t('Personal Data','Personal Data') }}</b-button>
							<br>
							<b-row>
								<b-col cols="2">
									<img src="https://devel.cocoatrace.com/api//images/Photo/default-user.png">
								</b-col>
								<b-col cols="">
									<b-form-group id="fieldset-1" description="" :label="$t('Name', 'Nama')">
										<b-form-input v-model="detailItems.PersonName" v-validate="'required'" :disabled="action === 'update'? false : true" :state="validateState('name')" name="name"></b-form-input>
										<small class="text-danger">
											{{ veeErrors.first("name") }}
										</small>
									</b-form-group>

									<b-form-group id="fieldset-1" description="" label="Gender" :disabled="action === 'update'? false : true">
										<b-form-radio-group v-slot="{ ariaDescribedby }" label="Select Options : ">
											<b-form-radio v-model="detailItems.GenderID" :aria-describedby="ariaDescribedby" name="some-radios" value="5">Male</b-form-radio>
											<b-form-radio v-model="detailItems.GenderID" :aria-describedby="ariaDescribedby" name="some-radios" value="6">Female</b-form-radio>
										</b-form-radio-group>
									</b-form-group>

									<b-form-group id="fieldset-1" disabled description="" label="Farmer ID">
										<b-form-input v-model="detailItems.FarmerID"></b-form-input>
									</b-form-group>

									<b-form-group id="fieldset-1" disabled description="" label="Farmer Certification Type">
										<b-form-input v-model="detailItems.EntityID"></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>
							<b-button block disabled variant="default" size="md" class="text-left" style="background:#747D86 !important; color:white">General Data</b-button>
							<br>
							<b-row>
								<b-col>
									<b-form-group>
										<label for=""><b>Family Status</b></label>
										<treeselect
											v-model="detailItems.MaritalStatus"
											:multiple="false"
											:options="arrFamily"
											:disabled="action === 'update'? false : true"
											name="family_status"
											:value-consists-of="valueConsistsOf"
											:state="validateState('family_status')"
										/>
									</b-form-group>
									<b-form-group id="fieldset-1" description="" label="Birth Date" :disabled="action === 'update'? false : true">
										<b-form-input v-model="detailItems.BirthDate" type="date"></b-form-input>
									</b-form-group>

									<b-form-group id="fieldset-1" description="" label="Age" :disabled="action === 'update'? false : true">
										<b-form-input v-model="detailItems.Age" type="number"></b-form-input>
									</b-form-group>
								</b-col>

								<b-col>
									<b-input-group id="fieldset-1" prepend="+" class="mt-1">
										<b-form-input 
											v-model="detailItems.PhoneNumber" 
											type="number"
											name="phoneNumber"
										></b-form-input>										
									</b-input-group>
									<div class="">	
										<i>*Number format must use country code. (Ex : +62)</i>
									</div>
									
									<b-form-group>
										<label for=""><b>Type ID</b></label>
										<treeselect
											v-model="detailItems.IdentityTypeId"
											:multiple="false"
											:options="arrTypeId"
											:disabled="action === 'update'? false : true"
											name="farmers"

											:value-consists-of="valueConsistsOf"
											:state="validateState('province')"
										/>
									</b-form-group>

									<b-form-group id="fieldset-1" description="" label="Number ID" :disabled="action === 'update'? false : true">
										<b-form-input v-model="detailItems.IdentityNumber"></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>

							<b-button block disabled variant="default" size="md" class="text-left" style="background:#747D86 !important; color:white">Address & Location</b-button>
							<br>
							<b-row>
								<b-col>
									<b-form-group>
										<label for=""><b>Countries</b></label>
										<treeselect
											v-model="CountryID"
											:multiple="false"
											:options="arrCountries"
											:disabled="action === 'update'? false : true"
											name="farmers"
											:value-consists-of="valueConsistsOf"
											:state="validateState('countries')"
										/>
									</b-form-group>
									<b-form-group>
										<label for=""><b>Province</b></label>
										<treeselect
											v-model="ProvinceID"
											:multiple="false"
											:options="arrProvinces"
											:disabled="action === 'update'? false : true"
											name="farmers"
											:value-consists-of="valueConsistsOf"
											:state="validateState('province')"
										/>
									</b-form-group>

									<b-form-group>
										<label for=""><b>District</b></label>
										<treeselect
											v-model="DistrictID"
											:multiple="false"
											:options="arrDistricts"
											:disabled="action === 'update'? false : true"
											name="farmers"
											:value-consists-of="valueConsistsOf"
											:state="validateState('district')"
										/>
									</b-form-group>
								</b-col>

								<b-col>
									<b-form-group>
										<label for=""><b>Sub District</b></label>
										<treeselect
											v-model="SubDistrictID"
											:multiple="false"
											:options="arrSubDistrict"
											:disabled="action === 'update'? false : true"
											name="farmers"
											:value-consists-of="valueConsistsOf"
											:state="validateState('sub_district')"
										/>
									</b-form-group>

									<b-form-group>
										<label for=""><b>Village</b></label>
										<treeselect
											v-model="VillageID"
											:multiple="false"
											:options="arrVillage"
											:disabled="action === 'update'? false : true"
											name="farmers"
											:value-consists-of="valueConsistsOf"
											:state="validateState('village')"
										/>
									</b-form-group>

									<b-form-group id="fieldset-1" description="" label="Address" :disabled="action === 'update'? false : true">
										<b-form-input v-model="detailItems.Address"></b-form-input>
									</b-form-group>

									<!-- <b-form-group id="fieldset-1" description="" label="File ID" :disabled="action === 'update'? false : true">
										<b-form-input v-model="detailItems.xxx"></b-form-input>
									</b-form-group> -->

									<!-- <b-form-group>
										<label for=""><b>Farmers</b></label>
										<treeselect
											v-model="detailItems.bank"
											:multiple="false"
											:options="arrFarmers"
											:disabled="action === 'update'? false : true"
											name="farmers"
											:value-consists-of="valueConsistsOf"
											:state="validateState('province')"
										/>
									</b-form-group> -->
								</b-col>
							</b-row>

							<b-button block disabled variant="default" size="md" class="text-left" style="background:#747D86 !important; color:white">Current Status</b-button>
							<br>
							<b-row>
								<b-col>
									<b-form-group id="fieldset-1" description="" label="Status :" :disabled="action === 'update'? false : true">
										<b-form-radio-group v-for="(a, key) in arrActiveStatus" :key="key" v-slot="{ ariaDescribedby }" label="Select Options : ">
											<b-form-radio v-model="detailItems.StatusActive" :aria-describedby="ariaDescribedby" name="some-radios" style="text-transform:uppercase" :value="a.id">{{ a.label }}</b-form-radio>
										</b-form-radio-group>
									</b-form-group>
								</b-col>
							</b-row>

							<b-button block disabled variant="default" size="md" class="text-left" style="background:#747D86 !important; color:white">Bank Information</b-button>
							<br>
							<b-row>
								<b-col>
									<b-form-group id="fieldset-1" description="" label="Bank Account Holder">
										<b-form-input v-model="detailItems.AccountHolderName" :disabled="action === 'update'? false : true"></b-form-input>
									</b-form-group>

									<b-form-group id="fieldset-1" description="">
										<label for=""><b>Bank</b></label>
										<v-select
											v-model="BankID"
											:options="arrBank"
											:state="validateState('branch')"
										/>
									</b-form-group>

									<b-form-group id="fieldset-1" description="">
										<label for=""><b>Branch</b></label>
										<v-select
											v-model="BankBranchID"
											:options="arrBankBranch"
											:state="validateState('branch')"
											name="branch"
										/>
									</b-form-group>
								</b-col>

								<b-col>
									<b-form-group id="fieldset-1" description="" label="Account Number">
										<b-form-input v-model="detailItems.AccountNumber" :disabled="action === 'update'? false : true"></b-form-input>
									</b-form-group>

									<b-form-group id="fieldset-1" description="">
										<label for=""><b>Account Holder Relation</b></label>
										<v-select
											v-model="AccountHolderRelation"
											:options="arrBankRelation"
											:state="validateState('branch')"
											name="branch"
										/>
									</b-form-group>

									<!-- <b-form-group id="fieldset-1" description="" label="Client Bank ID">
										<b-form-input v-model="detailItems.District" :disabled="action === 'update'? false : true"></b-form-input>
									</b-form-group> -->

									<!-- <b-form-group id="fieldset-1" description="" label="Account Owner Relationship with Farmer">
										<b-form-input v-model="detailItems.AccountHolderRelation" :disabled="action === 'update'? false : true"></b-form-input>
									</b-form-group> -->
								</b-col>
							</b-row>

							<div>
								<!-- <ktv-button
									class="mt-3"
									text="Print"
									icon="print"
									color="secondry"
									@click="print()"
								/> &nbsp; -->
								<ktv-button v-if="action === 'update'"
									class="mt-3"
									text="Update"
									icon="save"
									color="primary"
									@click="updateData()"
								/>
							</div>
						</b-tab>

						<b-tab title="Farm Data">
							<div style="margin-left:-1%">
								<b-card no-body>
									<b-tabs pills card vertical>
										<div v-for="(a, key) in farmItems" :key="key">
											<b-tab :title="`Farmer Data ${key+1}`">
												<b-card-text>
													<b-button block disabled variant="default" size="md" class="text-left" style="background:#747D86 !important; color:white">Data</b-button>
													<br>
													<b-row>
														<b-col>
															<b-form-group id="fieldset-1" disabled description="" label="Farm Number">
																<b-form-input v-model="a.District"></b-form-input>
															</b-form-group>

															<b-form-group id="fieldset-1" disabled description="" label="Commodity">
																<b-form-input v-model="a.CommodityName"></b-form-input>
															</b-form-group>

															<b-form-group id="fieldset-1" disabled description="" label="Luas Kebun (Ha)">
																<b-form-input v-model="a.GardenSize"></b-form-input>
															</b-form-group>

															<b-form-group id="fieldset-1" disabled description="" label="Production (Kg)">
																<b-form-input v-model="a.EstAnnualProduction"></b-form-input>
															</b-form-group>

															<b-form-group id="fieldset-1" disabled description="" label="Latitude">
																<b-form-input v-model="a.Latitude"></b-form-input>
															</b-form-group>

															<b-form-group id="fieldset-1" disabled description="" label="Longitude">
																<b-form-input v-model="a.Longitude"></b-form-input>
															</b-form-group>
														</b-col>
													</b-row>
													<br>
													<b-button block disabled variant="default" size="md" class="text-left" style="background:#747D86 !important; color:white">Farm Detail</b-button>
													<br>
													<b-row>
														<b-col>
															<b-form-group id="fieldset-1" disabled description="" label="Latest Farm Survey">
																<b-form-input v-model="a.LastSurveyNr"></b-form-input>
															</b-form-group>

															<b-form-group id="fieldset-1" disabled description="" label="Certification Standard">
																<b-form-input v-model="a.CertifiedStatus"></b-form-input>
															</b-form-group>

															<b-form-group id="fieldset-1" disabled description="" label="Land Ownership">
																<b-form-input v-model="a.LandOwnerShip"></b-form-input>
															</b-form-group>

															<b-form-group id="fieldset-1" disabled description="" label="AO Status">
																<b-form-input v-model="a.District"></b-form-input>
															</b-form-group>

															<b-form-group id="fieldset-1" disabled description="" label="Land Certification">
																<b-form-input v-model="a.LandCertificate"></b-form-input>
															</b-form-group>

															<b-form-group id="fieldset-1" disabled description="" label="Land User">
																<b-form-input v-model="a.LandUse"></b-form-input>
															</b-form-group>

															<b-form-group id="fieldset-1" disabled description="" label="Size (Ha)">
																<b-form-input v-model="a.GardenSize"></b-form-input>
															</b-form-group>

															<b-form-group id="fieldset-1" disabled description="" label="Estimated Production (Kg)">
																<b-form-input v-model="a.EstAnnualProduction"></b-form-input>
															</b-form-group>

															<b-form-group id="fieldset-1" disabled description="" label="Number of Cocoa Trees">
																<b-form-input v-model="a.District"></b-form-input>
															</b-form-group>

															<b-form-group id="fieldset-1" disabled description="" label="Comodity Type">
																<b-form-input v-model="a.District"></b-form-input>
															</b-form-group>

															<b-form-group id="fieldset-1" disabled description="" label="Cultivation System">
																<b-form-input v-model="a.District"></b-form-input>
															</b-form-group>

															<b-form-group id="fieldset-1" disabled description="" label="North Boundary">
																<b-form-input v-model="a.District"></b-form-input>
															</b-form-group>

															<b-form-group id="fieldset-1" disabled description="" label="South Boundary">
																<b-form-input v-model="a.District"></b-form-input>
															</b-form-group>

															<b-form-group id="fieldset-1" disabled description="" label="East Boundary">
																<b-form-input v-model="a.District"></b-form-input>
															</b-form-group>

															<b-form-group id="fieldset-1" disabled description="" label="West Boundary">
																<b-form-input v-model="a.District"></b-form-input>
															</b-form-group>
														</b-col>
													</b-row>
												</b-card-text>
											</b-tab>
										</div>
									</b-tabs>
								</b-card>
							</div>
						</b-tab>
					</b-tabs>
				</b-card>
			</b-card-group>
		</div>
	</div>
</template>

<script>
	// import the component
	import Treeselect from '@riophae/vue-treeselect'
	// import the styles
	import '@riophae/vue-treeselect/dist/vue-treeselect.css'
	import {
		KtvButton,
		KtvTable,
		KtvHeadForm,
	} from "@/components"

	import VueHtml2pdf from 'vue-html2pdf'

	export default {
		metaInfo: {
			title: "Farmer",
		},
		components: {
			// eslint-disable-next-line vue/no-unused-components
			KtvButton,
			// eslint-disable-next-line vue/no-unused-components
			KtvTable,
			// eslint-disable-next-line vue/no-unused-components
			KtvHeadForm,
			Treeselect,
			// eslint-disable-next-line vue/no-unused-components
			VueHtml2pdf
		},
		data() {
			return {
				/* eslint-disable global-require */
				iconedit: require("@/assets/images/icon/iconedit.png"),
				icondelete: require("@/assets/images/icon/icondelete.png"),
				iconview: require("@/assets/images/icon/iconview.png"),
				icondropdown: require("@/assets/images/icon/icondropdown.png"),
				isLoading: false,
				modeTable: "remote",
				columns: [],
				rows: [],
				totalRecords: 0,
				serverParams: {
					columnFilters: {
						id: "",
					},
					sort: [{
						field: "id",
						type: "ASC",
					},
					],
					currentPage: 1,
					rowPerpage: 10,
				},
				id : this.$route.query.id,
				action : this.$route.query.act,
				detailItems:{},
				farmItems:[],

				arrFarmers:[
					{
						id: 1,
						label: "Farmers 1"
					},
					{
						id: 2,
						label: "Farmers 2"
					},
					{
						id: 3,
						label: "Farmers 3"
					}],
				farmer : null,

				EntityID:"",
				PersonName:"",
				Gender:"",
				PhoneNumber:"",
				Street:"",
				IdentityNumber:"",
				AccountNumber:"",
				AccountHolderName:"",
				BirthDate:"",
				ObjectVillageID:"",
				CurrentStatus:"",
				MaritalStatus:"",

				valueConsistsOf: 'LEAF_PRIORITY',
				valueConsistsOfComp: 'BRANCH_PRIORITY',

				arrActiveStatus:[],
				StatusActive:"",

				arrTypeId :[],
				IdentityTypeId: null,

				arrFamily: [],
				FamilyRelationId: null,

				arrBank: [],
				BankID: null,

				arrBankBranch: [],
				BankBranchID: null,

				arrBankRelation: [],
				AccountHolderRelation: null,

				arrProvinces:[],
				ProvinceID: null,

				arrDistricts:[],
				DistrictID: null,

				arrSubDistrict: [],
				SubDistrictID: null,

				arrVillage: [],
				VillageID: null,

				arrCountries:[],
				CountryID:null,

				paramBranch: {
					BankId : "",
					Search: "",
				},
			}
		},
		computed: {

		},
		watch:{
			CountryID: {
				immediate: true,
				handler: function (value) {
					if (value) {
						this.getProvinces()
						this.arrProvinces = []
						// this.DistrictID = null
					}
				}
			},
			ProvinceID: {
				immediate: true,
				handler: function (value) {
					if (value) {
						this.getDistricts()
						this.arrDistricts = []
						// this.DistrictID = null
					}
				}
			},
			DistrictID: {
				immediate: true,
				handler: function (value) {
					if (value) {
						this.getSubDistrict()
						this.arrSubDistrict = []
					}
				}
			},
			SubDistrictID: {
				immediate: true,
				handler: function (value) {
					if (value) {
						this.getVillage()
						this.arrVillage = []
					}
				}
			},
			BankID: {
				immediate: true,
				handler: function (value) {
					if (value) {
						this.getBankBranch()
						this.arrBankBranch = []
					}
				}
			},
		},
		mounted() {
			

		},
		created(){
			this.loadDetailItem()
			this.loadDetailFarm()
			// console.log(this.$route.query.id)
			this.getTypeID()
			this.getActiveStatus()			
			this.getFamilyRelation()
			
			
			
			
		},
		methods: {
			getCountries() {
				this.$http
					.Get({}, this.$urlApi.fieldAgent.getCountries)
					.then((r) => {
						this.arrCountries = [];
						r.results.forEach(async (value, index) => {
							await this.arrCountries.push({
								index: index,
								id: value.value,
								label: value.text,
							});
						});
					})
					.catch(() => {
					})				
			},
			getProvinces() {
				let params = {CountryID: this.CountryID}
				this.$http
					.Get(params, this.$urlApi.fieldAgent.getProvinces)
					.then((r) => {
						this.arrProvinces = [];
						r.results.forEach(async (value, index) => {
							await this.arrProvinces.push({
								index: index,
								id: value.value,
								label: value.text,
							});
						});
					})
					.catch(() => {
					})				
			},

			getDistricts() {
				let params = {ProvinceID: this.ProvinceID}
				this.$http
					.Get(params, this.$urlApi.fieldAgent.getDistricts)
					.then((r) => {
						this.arrDistricts = [];
						r.results.forEach(async (value, index) => {
							await this.arrDistricts.push({
								index: index,
								id: value.value,
								label: value.text,
							});
						});
					})
					.catch(() => {
					})				
			},
			getSubDistrict() {
				let params = {DistrictID: this.DistrictID}
				this.$http
					.Get(params, this.$urlApi.sme.getSubDistrict)
					.then((r) => {
						this.arrSubDistrict = [];
						r.results.forEach(async (value, index) => {
							await this.arrSubDistrict.push({
								index: index,
								id: value.value,
								label: value.text,
							});
						});
					})
					.catch(() => {
					})				
			},
			getVillage() {
				let params = {SubDistrictID: this.SubDistrictID}
				this.$http
					.Get(params, this.$urlApi.sme.getVillage)
					.then((r) => {
						this.arrVillage = [];
						r.results.forEach(async (value, index) => {
							await this.arrVillage.push({
								index: index,
								id: value.value,
								label: value.text,
							});
						});
					})
					.catch(() => {
					})				
			},
			getBank() {
				this.$http
					.Get({}, this.$urlApi.farmer.getBank)
					.then((r) => {
						this.arrBank = [];
						r.results.forEach(async (value, index) => {
							await this.arrBank.push({
								index: index,
								id: value.BankID,
								label: value.BankName,
							});
						});
					})
					.catch(() => {
					})				
			},
			getBankBranch() {
				let params = {BankId: this.BankID.id}
				this.$http
					.Get(params, this.$urlApi.farmer.getBankBranch)
					.then((r) => {
						this.arrBankBranch = [];
						r.results.forEach(async (value, index) => {
							await this.arrBankBranch.push({
								index: index,
								id: value.BranchID,
								label: value.BranchName,
							});
						});
					})
					.catch(() => {
					})				
			},
			getBankRelation() {
				this.$http
					.Get({}, this.$urlApi.farmer.getBankRelation)
					.then((r) => {
						this.arrBankRelation = [];
						r.results.forEach(async (value, index) => {
							await this.arrBankRelation.push({
								index: index,
								id: value.EntityListOfValueID,
								label: value.Value,
							});
						});
					})
					.catch(() => {
					})				
			},
			getFamilyRelation() {
				this.$http
					.Get({}, this.$urlApi.farmer.getMaritalStatus)
					.then((r) => {
						this.arrFamily = [];
						r.results.forEach(async (value, index) => {
							await this.arrFamily.push({
								index: index,
								id: value.EntityListOfValueID,
								label: value.Value,
							});
						});
					})
					.catch(() => {
					})				
			},
			getTypeID() {
				this.$http
					.Get({}, this.$urlApi.farmer.getIDtype)
					.then((r) => {
						this.arrTypeId = [];
						r.results.forEach(async (value, index) => {
							await this.arrTypeId.push({
								index: index,
								id: value.EntityListOfValueID,
								label: value.Value,
							});
						});
					})
					.catch(() => {
					})				
			},
			getActiveStatus() {
				this.$http
					.Get({}, this.$urlApi.farmer.getActiveStatus)
					.then((r) => {
						this.arrActiveStatus = [];
						r.results.forEach(async (value, index) => {
							await this.arrActiveStatus.push({
								index: index,
								id: value.EntityListOfValueID,
								label: value.Value,
							});
						});
					})
					.catch(() => {
					})				
			},
			updateData() {
				this.$swal({
					title: 'Are you sure?',
					text: "",
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes'
				}).then((r) => {
					if (r.value) {
						this.$swal.fire({
							title: 'Updating data...',
							allowOutsideClick: false,
							onBeforeOpen: () => {
								this.$swal.showLoading()
								let payload = {
									EntityID : this.detailItems.EntityID,
									PersonName : '+' + this.detailItems.PersonName,
									Gender : this.detailItems.GenderID,
									PhoneNumber : this.detailItems.PhoneNumber,
									Street : this.detailItems.Address,
									IdentityNumber : this.detailItems.IdentityNumber,
									AccountNumber : this.detailItems.AccountNumber,
									AccountHolderName : this.detailItems.AccountHolderName,

									MaritalStatus : this.detailItems.MaritalStatus,
									BirthDate : this.detailItems.BirthDate,
									Age : this.detailItems.Age,

									AccountHolderRelation : this.AccountHolderRelation.id,

									BankID : this.BankID.id,
									BankBranchID : this.BankBranchID.id,

									ActiveStatus : this.detailItems.StatusActive,
									IdentityType : this.detailItems.IdentityTypeId,

									// ProvinceID : this.ProvinceID,
									// DistrictID : this.DistrictID,
									// SubDistrictID : this.SubDistrictID,
									ObjectVillageID : this.VillageID,
								}
								this.$http.
									Put(payload, this.$urlApi.farmer.update)
									.then(async (response) => {
										if(response.success){
											await this.$swal('Success!', 'The data does not change immediately, please wait...', 'success'); return
										}
										if(response.success === false){
											if(response.error_message === "ACCOUNT_NO_ALREADY_EXIST"){
												await this.$swal("Error!", 'Account Number Already Exist', "error")
											}if(response.error_message === "PHONE_ALREADY_EXIST"){
												await this.$swal("Error!","Phone Number Already Exist", "error")
											}
										}
										await this.loadItems()
									})
									.catch(async () => {
										// await this.$swal("Error!", error , "error")
									}).finally(() => this.$swal.close())
							}
						})
					}
				});

			},
			print(){
				this.$refs.html2Pdf.generatePdf()
			},
			loadDetailItem() {
				this.$swal.fire({
					title: 'Loading...',
					allowOutsideClick: false,
					onBeforeOpen: () => {
						this.$swal.showLoading()
						let params = { EntityID : this.id}
						this.$http.
							Get(params, this.$urlApi.farmer.detail)
							.then(async(response) => {
								if (response.success) {
									this.detailItems = response.results

									this.CountryID = response.results.CountryID
									this.ProvinceID = response.results.ProvinceID
									this.DistrictID = response.results.DistrictID
									this.SubDistrictID = response.results.SubDistrictID
									this.VillageID = response.results.VillageID

									this.BankID = {
										'id' : response.results.BankID, 
										'label' : response.results.BankName
									};
									this.BankBranchID = {
										'id' : response.results.BankBranchID, 
										'label' : response.results.BranchName
									};

									this.AccountHolderRelation = {
										'id' : response.results.AccountHolderRelation, 
										'label' : response.results.AccountHolderRelationText
									};
									await this.getCountries()
									await this.getProvinces()
									await this.getDistricts()
									await this.getSubDistrict()
									await this.getVillage()
									await this.getBank()
									await this.getBankBranch()
									await this.getBankRelation()

								} else {
									this.$swal("Error!", response.error_massage, "error")
								}
							})
							.catch((error) => {
								this.$swal("Error!", error.response, "error")
							}).finally(() => this.$swal.close())
					}
				})
			},
			loadDetailFarm() {
				this.$swal.fire({
					title: 'Loading...',
					allowOutsideClick: false,
					onBeforeOpen: () => {
						this.$swal.showLoading()
						let params = { EntityID : this.id}
						this.$http.
							Get(params, this.$urlApi.farmer.detailFarm)
							.then((response) => {
								if (response.success) {
									this.farmItems = response.results
								} else {
									this.$swal("Error!", response.error_massage, "error")
								}
							})
							.catch((error) => {
								this.$swal("Error!", error.response, "error")
							}).finally(() => this.$swal.close())
					}
				})
			},
			openForm() {
			},
			backToList() {
				this.$router.back()
			},

		}
	}
</script>

<style scoped>
	.dropdownitem-action:hover a,
	.dropdownitem-action:focus a {
		background-color: #CAEAFF !important;
	}
	.card-header, .card-footer {
    border-color: rgba(0, 0, 0, 0.03);
    background: #54A7DC !important;
}
</style>